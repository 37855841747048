import '@styles/globals.css'

import { AlertMessage } from '@components/AlertMessage'
import { Loader } from '@components/Loader'
import { ThemeProvider } from '@mui/material'
import { useStore } from '@redux/index'
import { theme } from '@styles/theme'
import buildId from 'build.json'
import { Layout } from 'layouts/global'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

function MyApp({ Component, pageProps }) {
  const store = useStore(pageProps.initialReduxState)
  const persistor = persistStore(store, {}, function () {
    persistor.persist()
  })

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Layout>
              <Component {...pageProps} />
              {process.env.NEXT_PUBLIC_TEST_BUILD_ID === 'true' && (
                <AlertMessage
                  message={`Version: ${buildId.version}`}
                  severity='info'
                />
              )}
            </Layout>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </>
  )
}

export default MyApp
